import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Header from '../components/header.js'
import Footer from '../components/footer.js'
import Category from '../components/category.js'
import Tag from '../components/tag.js'
import Location from '../components/location.js'

const Template = ({ data, pageContext }) => {
  // Work around the problem that markdownRemark is `null` in HRM.
  if (!data.markdownRemark) {
    return <div />
  }

  const meta = data.markdownRemark.frontmatter
  return (
    <div className="page">
      <div className="container">
        <Helmet title={`${meta.title} - ${data.site.siteMetadata.blogTitle}`} />
        <Header
          siteTitle={data.site.siteMetadata.blogTitle}
          siteSubTitle={data.site.siteMetadata.subtitle}
        />
        <div className="content">
          <article>
            <h1>{meta.title}</h1>
            <div className="meta">
              <span className="value">
                <i className="icon-calendar" />
                {meta.date}
              </span>
              <Category categories={meta.categories} />
              <Tag tags={meta.tags} />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
            <Location prev={pageContext.prev} next={pageContext.next} />
          </article>
        </div>
      </div>
      <Footer
        copyright={data.site.siteMetadata.copyright}
        repositoryName={data.site.siteMetadata.repositoryName}
        repositoryLink={data.site.siteMetadata.repositoryLink}
      />
    </div>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        repositoryName
        repositoryLink
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
        categories
        tags
        excerpt
      }
    }
  }
`

export default Template
