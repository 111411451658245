import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const Location = ({ prev, next }) => {
  if (!(prev || next)) {
    return null
  }

  return (
    <nav className="location">
      {prev ? (
        <div className="prev to">
          <i className="icon-chevron-thin-left" />
          <Link to={prev.frontmatter.path}>{prev.frontmatter.title}</Link>
        </div>
      ) : (
        <div className="prev to" />
      )}
      {next ? (
        <div className="next to">
          <Link to={next.frontmatter.path}>{next.frontmatter.title}</Link>
          <i className="icon-chevron-thin-right" />
        </div>
      ) : (
        <div className="next to" />
      )}
      <div className="clearfix" />
    </nav>
  )
}

Location.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object
}

export default Location
